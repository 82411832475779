//格式化日期
// 示例：<td v-datetime></td>
import moment from 'moment'

export default {
    name: 'datetime',
    directive: (el, binding) => {
        let val = $(el).html()
        console.log(val)
        if (val) {
            $(el).html(moment(val).format('yyyy-MM-DD HH:mm:ss'))
        }
    },
}