//用于指令，货币金额格式化
//例如：<td v-money="{places:2,symbol:'$',unit:''}">{{ item.BillsAmount }}</td> 根据需求可自定义传参获取需要的格式
export default {
    name: 'money',
    directive: {
        mounted(el, binding) {
            let item = $(el)
            let number = item.html()
            let places = binding.value === undefined ? undefined : binding.value.places
            let symbol = binding.value === undefined ? undefined : binding.value.symbol
            let unit = binding.value === undefined ? undefined : binding.value.unit
            let thousand = binding.value === undefined ? undefined : binding.value.thousand
            let decimal = binding.value === undefined ? undefined : binding.value.decimal
            let result = ''
            number = number || 0;
            //保留的小位数 否则默认保留两位
            places = !isNaN(places = Math.abs(places)) ? places : 2;
            //symbol表示前面表示的标志是￥
            symbol = symbol !== undefined ? symbol : "";
            //unit 后面显示单位 元
            unit = unit !== undefined ? unit : "";
            //thousand表示每几位用,隔开,是货币标识
            thousand = thousand != undefined ? thousand : ",";
            //decimal表示小数点
            decimal = decimal || ".";
            //negative表示如果钱是负数有就显示“-”如果不是负数 就不显示负号
            //i表示处理过的纯数字
            var negative = number < 0 ? "-" : "",
                i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
                j = (j = i.length) > 3 ? j % 3 : 0;
            result = symbol + negative + (j ? i.substr(0, j) + thousand : "") +
                i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal +
                    Math.abs(number - i).toFixed(places).slice(2) : "") + unit;
            item.html(result)
        },
        updated(el, binding) {
            let item = $(el)
            let number = item.html()
            let places = binding.value === undefined ? undefined : binding.value.places
            let symbol = binding.value === undefined ? undefined : binding.value.symbol
            let unit = binding.value === undefined ? undefined : binding.value.unit
            let thousand = binding.value === undefined ? undefined : binding.value.thousand
            let decimal = binding.value === undefined ? undefined : binding.value.decimal
            let result = ''
            if (!isNaN(number)) {
                number = number || 0;
                //保留的小位数 否则默认保留两位
                places = !isNaN(places = Math.abs(places)) ? places : 2;
                //symbol表示前面表示的标志是￥
                symbol = symbol !== undefined ? symbol : "";
                //unit 后面显示单位 元
                unit = unit !== undefined ? unit : "";
                //thousand表示每几位用,隔开,是货币标识
                thousand = thousand != undefined ? thousand : ",";
                //decimal表示小数点
                decimal = decimal || ".";
                //negative表示如果钱是负数有就显示“-”如果不是负数 就不显示负号
                //i表示处理过的纯数字
                var negative = number < 0 ? "-" : "",
                    i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "",
                    j = (j = i.length) > 3 ? j % 3 : 0;
                result = symbol + negative + (j ? i.substr(0, j) + thousand : "") +
                    i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal +
                        Math.abs(number - i).toFixed(places).slice(2) : "") + unit;
                item.html(result)
            }
        }
    }
}