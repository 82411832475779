//用于指令，文件大小转化
//例如：<td v-file-size>{{ item.FileSize }}</td>
export default {
    name: 'file-size',
    directive: {
        mounted(el, binding) {
            let item = $(el)
            let number = item.html()
            console.log(number)
            let result = ''
            number = number || 0;
            let k = 1024
            let sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
            let i = Math.floor(Math.log(number) / Math.log(k))
            result = (number / Math.pow(k, i)).toPrecision(3) + "" + sizes[i]
            item.html(result)
        },
        updated(el, binding) {
            let item = $(el)
            let number = item.html()
            let result = ''
            number = number || 0;
            if (!isNaN(number)) {
                let k = 1024
                let sizes = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
                let i = Math.floor(Math.log(number) / Math.log(k))
                result = (number / Math.pow(k, i)).toPrecision(3) + "" + sizes[i]
                item.html(result)
            }
        }
    }
}