<template>
    <el-container>
        <el-aside width="200px">
            <el-menu
                active-text-color="#ffd04b"
                background-color="#545c64"
                class="home-menu"
                :router="true"
                :unique-opened="true"
                :default-active="defaultMenu"
                text-color="#fff">
                <!--                /admin/homeInfo-->
                <el-menu-item class="text-primary" index="" @click="setBreadcrumb('首页','首页')">
                    <span style="font-size: 17px;">存知科技管理平台</span>
                </el-menu-item>
                <el-sub-menu v-for="menuOne in modules" :index="menuOne.nameEn">
                    <template #title>
                        <el-icon>
                            <briefcase v-if="menuOne.nameEn==='factoryInfo'"/>
                            <setting v-if="menuOne.nameEn==='system'"/>
                            <document v-if="menuOne.nameEn==='project'"/>
                        </el-icon>
                        <span>{{ menuOne.nameCn }}</span>
                    </template>
                    <el-menu-item v-for="menuTwo in menuOne.children" :index="setPath(menuTwo.nameEn)"
                                  @click="setBreadcrumb(menuTwo.nameCn,menuTwo.parentNameCn)">
                        {{ menuTwo.nameCn }}
                    </el-menu-item>
                </el-sub-menu>
            </el-menu>
        </el-aside>
        <el-container>
            <el-header height="60px">
                <el-menu mode="horizontal" :ellipsis="false">
                    <el-menu-item index="1">
                        <el-icon>
                            <User/>
                        </el-icon>
                        欢迎，
                        <span class="text-danger">{{ userName }}</span>
                    </el-menu-item>
                    <div class="flex-grow"></div>
                    <el-menu-item index="2" class="text-danger" @click="logOut">
                        <el-icon>
                            <SwitchButton/>
                        </el-icon>
                        <span>退出</span>
                    </el-menu-item>
                </el-menu>
            </el-header>
            <el-main class="home-main">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item>{{ curParentName }}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ curPageName }}</el-breadcrumb-item>
                </el-breadcrumb>
                <router-view/>
            </el-main>
        </el-container>
    </el-container>
</template>

<script setup>
import {useStore} from 'vuex'
import {useLogout} from '@/use/system/useAdminUser'
import {ref} from 'vue'
import {useRoute} from 'vue-router'
//用来刷新的时候 保持菜单选中状态
const route = useRoute()
const defaultMenu = ref(route.path)

const store = useStore()
const userName = store.state.userName
const {
    logOut
} = useLogout()
const modules = JSON.parse(store.state.auth)
const setPath = nameEn => {
    return '/admin/' + nameEn
}
const curPageName = ref('首页')
const curParentName = ref('首页')
const setBreadcrumb = (pageName, parentName) => {
    curPageName.value = pageName
    curParentName.value = parentName
}
</script>
<style lang="scss">
.home-menu {
    min-height: 100%;
}

.flex-row {
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;
}

.flex-col {
    display: flex;
}

.flex-grow {
    -webkit-flex-grow: 1;
    flex-grow: 1;
}

.home-main {
    background-color: #f0f3f4;
}

.el-menu--horizontal {
    border-bottom: none !important;
}

.text-center {
    text-align: center;
}

//页面容器
.view-container {
    margin-top: 20px;
    border: 1px solid #edf1f2;
    border-radius: 2px 2px 0 0;
}

//页面搜索容器
.view-search {
    display: flex;
    padding-top: 20px;
    padding-left: 10px;
    background-color: #f6f8f8;
}

//页面表格容器
.view-table {
    display: flex;
}

//页面表格分页容器
.view-pagination {
    padding: 7px;
    background-color: white;
    display: flex;
}


// 树结构页面样式
.view-tree {
    min-height: 700px;
    align-items: stretch;
    -webkit-align-items: stretch;
    background-color: white;
}

.view-tree-left {
    padding: 10px;
    width: 250px;
    //border-right: 2px solid #edf1f2;
}

.view-tree-right {
    //padding: 10px;
    background-color: white;
}

// 左右分页页面样式
.view-list {
    min-height: 700px;
    align-items: stretch;
    -webkit-align-items: stretch;
    background-color: #f6f8f8;
}

.view-list-left {
    padding: 5px;
    flex-shrink: 0;
    width: 200px;
    background-color: var(--el-fill-color-light) !important;
}

.view-right {
    background-color: white;
    flex: 1;
    width: 0;
}
</style>