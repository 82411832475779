import {createStore} from 'vuex'

export default createStore({
    state: {
        userName: localStorage.getItem('userName'),
        phoneNo: sessionStorage.getItem('phoneNo'),
        token: sessionStorage.getItem('token'),
        auth: sessionStorage.getItem('auth'),
    },
    getters: {},
    mutations: {
        setUserName(state, val) {
            state.userName = val
            localStorage.setItem('userName', val)
        },
        setToken(state, val) {
            state.token = val
            sessionStorage.setItem('token', val)
        },
        setPhoneNo(state, val) {
            state.phoneNo = val
            sessionStorage.setItem('phoneNo', val)
        },
        setAuth(state, val) {
            let auth = JSON.stringify(val)
            state.auth = auth
            sessionStorage.setItem('auth', auth)
        },
    },
    actions: {},
    modules: {}
})