import {reactive, ref, inject} from 'vue'
import {useStore} from 'vuex'
import {sha256_digest} from "@/util/sha"
import {cnPhoneNoReg} from '@/util/validator'
import {ElMessage} from "element-plus";
import {
    userDelete, userLogin, userGetPage, userSave, userResetPassword, userGetList,
    factoryUserGetPage, doctorUserGetPage, factoryUserResetPwd, doctorUserResetPwd,
    factoryUserUnlock, factoryUserValid, doctorUserUnlock, doctorUserValid,
    factoryUserUnlockAll, doctorUserUnlockAll, RSAGenerate
} from '@/api/system'
import {defaultPageSize} from "@/util/constant";

// 生成RSA键值对PCKS8
function useGenerateRSA() {
    const RSAResult = reactive({
        publicKey: '',
        privateKey: ''
    })
    const generateRSALoading = ref(false)
    const generateRSA = async () => {
        if (generateRSALoading.value) return
        generateRSALoading.value = true
        let res = await RSAGenerate()
        if (res) Object.assign(RSAResult, res)
        generateRSALoading.value = false
    }
    return {
        RSAResult,
        generateRSALoading,
        generateRSA,
    }
}

function useGetUserList() {
    const userList = ref([])
    const getUserListLoading = ref(false)
    const getUserList = async () => {
        if (getUserListLoading.value) return
        getUserListLoading.value = true
        userList.value = []
        let res = await userGetList()
        if (res) {
            userList.value = res
        }
        getUserListLoading.value = false
    }
    return {
        userList,
        getUserList,
        getUserListLoading
    }
}

function useSaveUser() {
    const user = reactive({
        id: 0,
        phoneNo: '',
        password: '',
        workerName: '',
        roleId: 0,
    })
    const initUser = () => {
        user.id = 0
        user.phoneNo = ''
        user.password = ''
        user.workerName = ''
        user.roleId = 0
    }
    const userFormRef = ref()
    const userAddRules = reactive({
        phoneNo: [
            {required: true, pattern: cnPhoneNoReg, message: '请输入正确的手机号码', trigger: 'blur'},
        ],
        password: [
            {required: true, message: '请输入初始密码', trigger: 'blur'},
        ],
        workerName: [
            {required: true, message: '请输入姓名', trigger: 'blur'},
        ],
        roleId: [
            {required: true, message: '请选择角色', trigger: 'change'},
        ],
    })
    const saveUserLoading = ref(false)
    const saveUserResult = ref(false)
    const saveUser = async () => {
        saveUserResult.value = false
        if (saveUserLoading.value) return
        await userFormRef.value.validate(async (valid, fields) => {
            if (!valid) {
                return
            }
            saveUserLoading.value = true
            let param = {
                id: user.id,
                phoneNo: user.phoneNo,
                password: sha256_digest(user.password),
                workerName: user.workerName,
                roleId: user.roleId,
            }
            saveUserResult.value = await userSave(param)
            saveUserLoading.value = false
        })
    }

    return {
        user,
        initUser,
        userFormRef,
        userAddRules,
        saveUserLoading,
        saveUserResult,
        saveUser,
    }
}

function useResetPassword() {
    const resetPasswordParam = reactive({
        id: 0,
        password: ''
    })
    const resetPasswordRef = ref()
    const resetPasswordRules = reactive({
        password: [
            {required: true, message: '请输入密码', trigger: 'blur'},
        ]
    })
    const resetPasswordLoading = ref(false)
    const resetPasswordResult = ref(false)
    const resetPassword = async () => {
        resetPasswordResult.value = false
        if (resetPasswordLoading.value) return
        await resetPasswordRef.value.validate(async (valid, fields) => {
            if (!valid) {
                return
            }
            resetPasswordLoading.value = true
            let param = {
                id: resetPasswordParam.id,
                password: sha256_digest(resetPasswordParam.password)
            }
            resetPasswordResult.value = await userResetPassword(param)
            resetPasswordLoading.value = false
        })
    }
    return {
        resetPasswordParam,
        resetPasswordRef,
        resetPasswordRules,
        resetPasswordLoading,
        resetPasswordResult,
        resetPassword
    }
}

function useResetFactoryPwd() {
    const resetFactoryPwdParam = reactive({
        id: 0,
        password: ''
    })
    const resetFactoryPwdLoading = ref(false)
    const resetFactoryPwdResult = ref(false)
    const resetFactoryPwd = async () => {
        if (!resetFactoryPwdParam.password) {
            ElMessage.error('请输入密码')
            return
        }
        if (resetFactoryPwdParam.password.length < 6) {
            ElMessage.error('密码长度不能小于6位')
            return
        }
        if (resetFactoryPwdLoading.value) return
        resetFactoryPwdLoading.value = false
        let param = {
            id: resetFactoryPwdParam.id,
            password: sha256_digest(resetFactoryPwdParam.password)
        }
        resetFactoryPwdResult.value = await factoryUserResetPwd(param)
        resetFactoryPwdLoading.value = true
    }
    return {
        resetFactoryPwdParam,
        resetFactoryPwdLoading,
        resetFactoryPwdResult,
        resetFactoryPwd
    }
}

function useResetDoctorPwd() {
    const resetDoctorPwdParam = reactive({
        id: 0,
        password: ''
    })
    const resetDoctorPwdLoading = ref(false)
    const resetDoctorPwdResult = ref(false)
    const resetDoctorPwd = async () => {
        if (!resetDoctorPwdParam.password) {
            ElMessage.error('请输入密码')
            return
        }
        if (resetDoctorPwdParam.password.length < 6) {
            ElMessage.error('密码长度不能小于6位')
            return
        }
        if (resetDoctorPwdLoading.value) return
        resetDoctorPwdLoading.value = false
        let param = {
            id: resetDoctorPwdParam.id,
            password: sha256_digest(resetDoctorPwdParam.password)
        }
        resetDoctorPwdResult.value = await doctorUserResetPwd(param)
        resetDoctorPwdLoading.value = true
    }
    return {
        resetDoctorPwdParam,
        resetDoctorPwdLoading,
        resetDoctorPwdResult,
        resetDoctorPwd
    }
}

// 登录
function useLogin() {
    const store = useStore()
    const toPage = inject('toPage')
    const user = reactive({
        phoneNo: '',
        password: ''
    })
    const loginLoading = ref(false)
    const login = async () => {
        if (loginLoading.value) return
        if (!user.phoneNo) {
            ElMessage.error('请输入手机号')
            return
        }
        if (!user.password) {
            ElMessage.error('请输入密码')
            return
        }
        loginLoading.value = true
        let param = {
            phoneNo: user.phoneNo,
            password: sha256_digest(user.password)
        }
        try {
            let res = await userLogin(param)
            if (res.code === 0) {
                ElMessage.success('登录成功')
                store.commit('setUserName', res.data.userName)
                store.commit('setPhoneNo', res.data.phoneNo)
                store.commit('setToken', res.data.token)
                store.commit('setAuth', res.data.modules)
                toPage('/admin/homeInfo')
            } else {
                ElMessage.error(res.message)
            }
        } catch (e) {
            ElMessage.error('请求错误，请检查网络或者联系客服')
        }
        loginLoading.value = false
    }
    return {
        user,
        loginLoading,
        login,
    }
}

// 登出
function useLogout() {
    const store = useStore()
    const toPage = inject('toPage')
    const logOut = () => {
        store.commit('setUserName', '')
        store.commit('setToken', '')
        store.commit('setPhoneNo', '')
        toPage('/')
    }
    return {
        logOut
    }
}

// 管理员用户分页
function useGetPageUser() {
    const getPageUserParam = reactive({
        pageIndex: 1,
        pageSize: defaultPageSize,
        userName: '',
        phoneNo: ''
    })
    const getPageUserResult = reactive({
        totalCount: 0,
        totalPages: 0
    })
    const initGetPageUserParam = () => {
        getPageUserParam.pageIndex = 1;
        getPageUserParam.userName = '';
        getPageUserParam.phoneNo = '';
    }
    const userPage = ref([])
    const getPageUserLoading = ref(false)
    const getPageUser = async () => {
        if (getPageUserLoading.value) return
        getPageUserLoading.value = true
        let data = await userGetPage(getPageUserParam)
        if (data) {
            if (data.list && data.list.length) {
                userPage.value = data.list
            } else {
                userPage.value = []
            }
            getPageUserResult.totalPages = Math.ceil(data.totalCount / getPageUserParam.pageIndex)
            getPageUserResult.totalCount = data.totalCount
        }
        getPageUserLoading.value = false
    }
    return {
        getPageUserParam,
        getPageUserResult,
        initGetPageUserParam,
        userPage,
        getPageUserLoading,
        getPageUser
    }
}

function useDeleteUser() {
    const deleteUserLoading = ref(false)
    const deleteUserResult = ref(false)
    const deleteUser = async id => {
        if (deleteUserLoading.value) return
        deleteUserLoading.value = true
        deleteUserResult.value = false
        deleteUserResult.value = await userDelete({id: id})
        deleteUserLoading.value = false
    }
    return {
        deleteUserLoading,
        deleteUserResult,
        deleteUser,
    }
}

function useGetPageFactoryUser() {
    const getPageFactoryUserParam = reactive({
        pageIndex: 1,
        pageSize: defaultPageSize,
        userName: '',
    })
    const getPageFactoryUserResult = reactive({
        totalCount: 0,
        totalPages: 0
    })
    const initGetPageFactoryUserParam = () => {
        getPageFactoryUserParam.pageIndex = 1;
        getPageFactoryUserParam.userName = '';
    }
    const factoryUserPage = ref([])
    const getPageFactoryUserLoading = ref(false)
    const getPageFactoryUser = async () => {
        if (getPageFactoryUserLoading.value) return
        getPageFactoryUserLoading.value = true
        let data = await factoryUserGetPage(getPageFactoryUserParam)
        if (data) {
            if (data.list && data.list.length) {
                factoryUserPage.value = data.list
            } else {
                factoryUserPage.value = []
            }
            getPageFactoryUserResult.totalPages = Math.ceil(data.totalCount / getPageFactoryUserParam.pageIndex)
            getPageFactoryUserResult.totalCount = data.totalCount
        }
        getPageFactoryUserLoading.value = false
    }
    return {
        getPageFactoryUserParam,
        getPageFactoryUserResult,
        initGetPageFactoryUserParam,
        factoryUserPage,
        getPageFactoryUserLoading,
        getPageFactoryUser
    }
}

function useGetPageDoctorUser() {
    const getPageDoctorUserParam = reactive({
        pageIndex: 1,
        pageSize: defaultPageSize,
        userName: '',
    })
    const getPageDoctorUserResult = reactive({
        totalCount: 0,
        totalPages: 0
    })
    const initGetPageDoctorUserParam = () => {
        getPageDoctorUserParam.pageIndex = 1;
        getPageDoctorUserParam.userName = '';
    }
    const doctorUserPage = ref([])
    const getPageDoctorUserLoading = ref(false)
    const getPageDoctorUser = async () => {
        if (getPageDoctorUserLoading.value) return
        getPageDoctorUserLoading.value = true
        let data = await doctorUserGetPage(getPageDoctorUserParam)
        if (data) {
            if (data.list && data.list.length) {
                doctorUserPage.value = data.list
            } else {
                doctorUserPage.value = []
            }
            getPageDoctorUserResult.totalPages = Math.ceil(data.totalCount / getPageDoctorUserParam.pageIndex)
            getPageDoctorUserResult.totalCount = data.totalCount
        }
        getPageDoctorUserLoading.value = false
    }
    return {
        getPageDoctorUserParam,
        getPageDoctorUserResult,
        initGetPageDoctorUserParam,
        doctorUserPage,
        getPageDoctorUserLoading,
        getPageDoctorUser
    }
}

function useUnlockFactoryUser() {
    const unlockFactoryUserLoading = ref(false)
    const unlockFactoryUserResult = ref(false)
    const unlockFactoryUser = async id => {
        if (unlockFactoryUserLoading.value) return
        unlockFactoryUserLoading.value = true
        unlockFactoryUserResult.value = false
        unlockFactoryUserResult.value = await factoryUserUnlock({id: id})
        unlockFactoryUserLoading.value = false
    }
    return {
        unlockFactoryUserResult,
        unlockFactoryUserLoading,
        unlockFactoryUser
    }
}

function useUnlockAllFactoryUser() {
    const unlockAllFactoryUserLoading = ref(false)
    const unlockAllFactoryUserResult = ref(false)
    const unlockAllFactoryUser = async () => {
        if (unlockAllFactoryUserLoading.value) return
        unlockAllFactoryUserLoading.value = true
        unlockAllFactoryUserResult.value = false
        unlockAllFactoryUserResult.value = await factoryUserUnlockAll()
        unlockAllFactoryUserLoading.value = false
    }
    return {
        unlockAllFactoryUserResult,
        unlockAllFactoryUserLoading,
        unlockAllFactoryUser
    }
}

function useValidFactoryUser() {
    const validFactoryUserLoading = ref(false)
    const validFactoryUserResult = ref(false)
    const validFactoryUserParam = reactive({
        id: null,
        valid: null
    })
    const validFactoryUser = async () => {
        if (validFactoryUserLoading.value) return
        validFactoryUserLoading.value = true
        validFactoryUserResult.value = false
        validFactoryUserResult.value = await factoryUserValid(validFactoryUserParam)
        validFactoryUserLoading.value = false
    }
    return {
        validFactoryUserParam,
        validFactoryUserResult,
        validFactoryUserLoading,
        validFactoryUser
    }
}

function useUnlockDoctorUser() {
    const unlockDoctorUserLoading = ref(false)
    const unlockDoctorUserResult = ref(false)
    const unlockDoctorUser = async id => {
        if (unlockDoctorUserLoading.value) return
        unlockDoctorUserLoading.value = true
        unlockDoctorUserResult.value = false
        unlockDoctorUserResult.value = await doctorUserUnlock({id: id})
        unlockDoctorUserLoading.value = false
    }
    return {
        unlockDoctorUserResult,
        unlockDoctorUserLoading,
        unlockDoctorUser
    }
}

function useUnlockAllDoctorUser() {
    const unlockAllDoctorUserLoading = ref(false)
    const unlockAllDoctorUserResult = ref(false)
    const unlockAllDoctorUser = async () => {
        if (unlockAllDoctorUserLoading.value) return
        unlockAllDoctorUserLoading.value = true
        unlockAllDoctorUserResult.value = false
        unlockAllDoctorUserResult.value = await doctorUserUnlockAll()
        unlockAllDoctorUserLoading.value = false
    }
    return {
        unlockAllDoctorUserResult,
        unlockAllDoctorUserLoading,
        unlockAllDoctorUser
    }
}

function useValidDoctorUser() {
    const validDoctorUserLoading = ref(false)
    const validDoctorUserResult = ref(false)
    const validDoctorUserParam = reactive({
        id: null,
        valid: null
    })
    const validDoctorUser = async () => {
        if (validDoctorUserLoading.value) return
        validDoctorUserLoading.value = true
        validDoctorUserResult.value = false
        validDoctorUserResult.value = await doctorUserValid(validDoctorUserParam)
        validDoctorUserLoading.value = false
    }
    return {
        validDoctorUserParam,
        validDoctorUserResult,
        validDoctorUserLoading,
        validDoctorUser
    }
}

export {
    useGetUserList,
    useSaveUser,
    useResetPassword,
    useLogin,
    useGetPageUser,
    useLogout,
    useDeleteUser,
    useResetFactoryPwd,
    useGetPageFactoryUser,
    useResetDoctorPwd,
    useGetPageDoctorUser,
    useUnlockFactoryUser,
    useValidFactoryUser,
    useUnlockDoctorUser,
    useValidDoctorUser,
    useUnlockAllFactoryUser,
    useUnlockAllDoctorUser,
    useGenerateRSA,
}