// 通过枚举值的id获取枚举值名称 by oycm
// 示例：<td v-enum-text="factoryPaymentTypes"></td>

export default {
    name: 'enumText',
    directive: (el, binding) => {
        let arr=binding.value
        let val = $(el).html()
        if (val) {
            if(arr.find(x=>x.Id==val)){
                $(el).html(arr.find(x=>x.Id==val).Name)
            }
        }
    },
}