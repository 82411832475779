<template>
    <div class="login-container">
        <div></div>
        <div class="login-body">
            <el-row style="justify-content:center;">
                <p style="font-size: 25px">存知科技管理平台</p>
            </el-row>
            <el-row>
                <ul>
                    <li class="borderLi">
                        <div>
                            <img src="../assets/loginUser.png"/>
                            <input id="userName" type="text" v-focus v-model.trim="user.phoneNo" maxlength="20"
                                   ref="userNameRef"
                                   @keyup.enter="handleLogin" autoComplete="off"/>
                        </div>
                    </li>
                    <li class="borderLi">
                        <img src="../assets/loginPwd.png"/>
                        <input id="password" type="password" v-model.trim="user.password" maxlength="20"
                               ref="passwordRef"
                               @keyup.enter="handleLogin" autoComplete="off"/>
                    </li>
                    <li style="text-align: center;">
                        <el-button class="loginBtn"
                                   :loading="loginLoading" @click="handleLogin">登&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;录
                        </el-button>
                    </li>
                    <li style="text-align: center;">
                        <p style="font-size: 12px;color:white;padding-top:10px;" title="请联系存知科技有">忘记密码</p>
                    </li>
                </ul>
            </el-row>
        </div>
        <div class="login-footer">
            Copyright © 2023 All Rights Reserved. 杭州存知网络科技有限公司 版权所有
        </div>
    </div>
</template>

<script setup>
import {ref, reactive, onMounted} from 'vue'
import {useLogin} from "@/use/system/useAdminUser";
import {ElMessage} from "element-plus";

const {
    user,
    loginLoading,
    login,
} = useLogin()
onMounted(() => {
    let adminUserName = localStorage.getItem('adminUserName')
    if (!!adminUserName) {
        user.phoneNo = adminUserName
        passwordRef.value.focus()
    }
})
const userNameRef = ref()
const passwordRef = ref()
const handleLogin = async () => {
    if (!user.phoneNo) {
        userNameRef.value.focus()
        ElMessage.warning('请输入用户名')
    } else if (!user.password) {
        passwordRef.value.focus()
        ElMessage.warning('请输入密码')
    } else {
        localStorage.setItem('adminUserName', user.phoneNo)
        await login()
    }
}

</script>

<style scoped lang="scss">
.login-container {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: url("../assets/loginBg.jpg") no-repeat center center;
    background-size: 100% 100%;
    text-align: center;

    .login-body {
        width: 250px;
        height: 300px;
        color: #FFFFFF;
        background-color: #000000;
        background-color: rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        padding: 30px;

        p {
            margin: 2px;
            font-size: 32px;
            letter-spacing: 2px;
            font-weight: 100;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                width: 100%;
                height: 35px;
                padding: 0;
                margin-top: 30px;

                input {
                    width: 205px;
                    height: 25px;
                    text-indent: 15px;
                    border: none;
                    background-color: rgba(0, 0, 0, 0);
                    outline: none;
                    color: black;
                    font-size: 16px;
                    vertical-align: middle;
                    margin-top: 4px;
                }

                .loginBtn {
                    background-color: #4d75e1;
                    background-color: rgba(65, 105, 225, 0.8);
                    color: white;
                    font-size: 14px;
                    width: 250px;
                    height: 40px;
                    border: none;

                    &:hover {
                        background-color: #4d75e1;
                    }
                }
            }

            .borderLi {
                background-color: rgba(255, 255, 255, 0.9);
                border: 1px solid rgba(255, 255, 255, 0.49);
                border-radius: 1px;

                img {
                    height: 25px;
                    width: 25px;
                    vertical-align: middle;
                    margin-top: 4px;
                }
            }
        }
    }

    .login-footer {
        color: white;
        margin-bottom: 10px;
        font-size: 11px;
    }
}
</style>