// 进入页面自动focus by oycm
// 示例：<td v-focus></td>

export default {
    name: 'focus',
    directive: {
        mounted(el, binding) {
            el.focus()
        }
    },
}